import { createApp } from 'vue'
import App from './App.vue'
import {router} from './router'
import store from './store'

import ElementPlus from 'element-plus'
import './element-variables.scss'
import locale from 'element-plus/lib/locale/lang/zh-cn'

import "tailwindcss/tailwind.css"

import './utils/common'

const app = createApp(App)
app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')