<template>
  <div class="flex items-center">
    <div>
<!--      <img src="../assets/logo.png" alt="">-->
    </div>
    <div class="py-7 mx-8">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">立创教育工具集</el-breadcrumb-item>
        <el-breadcrumb-item v-if="$route.path != '/'">{{ $route.meta.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>


</template>
<script setup>
</script>