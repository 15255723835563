import copy1 from 'copy-to-clipboard';
import { ElMessage } from 'element-plus'
function copy(text:string):void{
    try{
        copy1(text)
        ElMessage({
            message: '复制成功',
            type: 'success',
        })
    }
    catch (e){
        ElMessage({
            message: '复制失败',
            type: 'error',
        })
    }
}

export {copy}