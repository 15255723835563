import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tools/activity_image',
    name: 'activity_image',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/active_image.vue'),
    meta:{
      title: '生成纯图片活动'
    }
  },
  {
    path: '/tools/generate_question_title',
    name: 'generate_question_title',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/generate_question_title.vue'),
    meta:{
      title: '题目特殊符号转义'
    }
  },
  {
    path: '/tools/generate_notice_url',
    name: 'generate_notice_url',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/generate_notice_url.vue'),
    meta:{
      title: '生成App通知链接'
    }
  },
  {
    path: '/tools/generate_jump_url',
    name: 'generate_jump_url',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/generate_jump_url.vue'),
    meta:{
      title: '生成App跳转链接'
    }
  },
  {
    path: '/tools/get_permissions_str',
    name: 'get_permissions_str',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/get_permissions_str.vue'),
    meta:{
      title: '生成主账号权限'
    }
  },
  {
    path: '/tools/generate_mp_article_url',
    name: 'generate_mp_article_url',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/generate_mp_article_url.vue'),
    meta:{
      title: '生成公众号文章链接'
    }
  },
  {
    path: '/tools/generate_activity_url',
    name: 'generate_activity_url',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/generate_activity_url.vue'),
    meta:{
      title: '生成活动链接'
    }
  },
  {
    path: '/tools/generate_micro_program_file_download_qr_link',
    name: 'generate_micro_program_file_download_qr_link',
    component: () => import(/* webpackChunkName: "about" */ '../views/tools/generate_micro_program_file_download_qr_link.vue'),
    meta:{
      title: '生成小程序下载文件页链接'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const toolsRoutes = routes.filter(i => i.path.startsWith('/tools')).map(i => ({'title': i?.meta?.title, 'url': i.path}))

export {router, routes, toolsRoutes}
